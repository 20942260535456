$primary-color: #0074e4;

.post-content {
  $image-margin: 2.5em;

  img {
    max-width: 100%;
    margin: 0 auto $image-margin;
    display: block;
  }

  .highlighter-rouge {
    margin-bottom: $image-margin;
  }

  figcaption {
    text-align: center;
    margin-top: -$image-margin;
    margin-bottom: 15px; // "p"'s margin bottom
  }
}

.cta-form {
  margin-top: 3rem;
  background-color: #f8faff;
  padding: 1rem;
  h4 {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
  }
}

.site-header {
  background-color: black;
  border-top: none;

  .site-title {
    color: white;
  }
}

.post, .post-list {
  margin-bottom: 3rem;

  a {
    padding: 1px 0 0 0;
    box-shadow: inset 0 -2px 0 $primary-color;
    text-decoration: none;
    background: 0 0;
    transition: box-shadow .2s;
  
    &:hover {
      box-shadow: inset 0 -1.1em 0 rgba(0, 116, 228, .2);
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 600px) {
  .site-nav .trigger .page-link {
    color: white;
  }
}

.post-list {
  margin-left: 30px;
}

.home, .post {
  li::before {
    transform: rotate(-5deg);
  }

  li:nth-of-type(2n+1)::before {
    transform: rotate(4deg);
  }

  ul li {
    position: relative;
    list-style: none;
    margin-bottom: calc(1.75rem / 2);

    &::before {
      display: inline-block;
      content: ' ';
      background: $primary-color;
      width: 14px;
      height: 5px;
      position: absolute;
      top: 10px;
      left: -26px;
    }
  }

  ol > li {
    padding-left: 30px;
    counter-increment: step-counter;
    position: relative;
    list-style: none;
    margin-bottom: calc(1.75rem / 2);
    &::before {
      content: counter(step-counter);
      margin-right: 1em;
      line-height: 1;
      background-color: $primary-color;
      box-shadow: rgba(128, 128, 128, 0.2) 0.2em 0.2em 0px;
      color: rgb(255, 255, 255);
      padding: 2px 0px;
      width: 2.7em;
      height: 1.2em;
      display: block;
      position: absolute;
      box-sizing: border-box;
      margin-left: -62px;
      margin-top: -3px;
      top: 8px;
      text-align: center;
      font-size: 0.9em;
      font-style: normal;
      font-family: sharp-sans, sans-serif;
      font-variant-numeric: lining-nums;
      font-feature-settings: "lnum";
    }
  }

  .post-link {
    font-size: 1em;
    display: inline;
  }

  .post-meta {
    display: none;
  }
}